import debounce from "lodash-es/debounce"

export interface TabItem {
  value: string
  label: string
  icon?: string
  scope?: AuthScope
}

const tabs = ref<Array<TabItem>>([])

export function useTabs() {
  const route = useRoute()
  const router = useRouter()

  const getValidTab = (tab: string) => {
    if (tabs.value.some((t) => t.value === tab)) {
      return tab
    }

    return tabs.value[0]?.value ?? undefined
  }

  const updateQuery = debounce(async (tab?: string) => {
    if (route.query.tab === getValidTab(tab ?? (route.query.tab as string) ?? "")) {
      return
    }

    const { title, organizationId } = route.meta

    await router.replace({
      query: {
        ...route.query,
        tab: getValidTab(tab ?? (route.query.tab as string) ?? ""),
      },
    })

    route.meta.title = title
    route.meta.organizationId = organizationId

    window.scrollTo(0, 0)
  }, 10)

  watch(tabs, () => updateQuery(), { deep: true, immediate: true })

  watch(
    () => route.query.tab,
    () => {
      if (route.query.tab !== getValidTab(route.query.tab as string)) {
        updateQuery()
      }
    },
    { immediate: true, deep: true },
  )

  return { tabs: tabs, updateTab: updateQuery }
}
